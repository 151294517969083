


import Hero from './Hero.js'
import Toolkit from './Toolkit';

export default function Home() {
    return (
      <div className="App">
      <Hero />
      <Toolkit />
      </div>
    );
  }